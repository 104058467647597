import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['fileUploadMessage', 'uploadedFilesList', 'fileUploadButton', 'filestackUploadForm', 'filenameInput', 'handleInput', 'documentSubmitForReviewForm', 'submitForReviewButton']

  initialize() {
    this._configureFilestackClient();
    this._setupFilestackUploadCallbacks();
    this._toggleFileUploadButtonVisibility();
    if (this.hasDocumentSubmitForReviewFormTarget) this._setupSubmitForReviewCallbacks();
  }

  openFilePicker() {
    let pickerOptions = {
      fromSources: ['local_file_system'],
      maxFiles: Number(this.fileUploadButtonTarget.dataset.maximumFileUploads),
      uploadInBackground: false,
      transformations: {
        crop: false,
        circle: false,
        rotate: false
      },
      accept: ['image/*', 'application/*', 'text/*'],
      storeTo: {
        workflows: [FILESTACK_CONFIGURATION.workflow_id]
      },
      onOpen: () => {
        this.fileUploadButtonTarget.disabled = true;
      },
      onClose: () => {
        this.fileUploadButtonTarget.disabled = false;
      },
      onFileUploadFinished: response => this._fileUploadFinishedCallbackHandler(response),
      onFileUploadFailed: response => this._fileUploadFailedCallbackHandler(),
      onFileSelected: file => {
        let maximumAllowedFileSize = this.fileUploadButtonTarget.dataset.maximumAllowedFileSize
        if (file.size > maximumAllowedFileSize * 1024 * 1024) {
            throw new Error('File too big, select something smaller than ' + maximumAllowedFileSize + ' MB');
        }
    }
    };
    this.filestackClient.picker(pickerOptions).open();
  }

  hideModal() {
    $(this.element).modal('hide');
  }

  _configureFilestackClient() {
    let securityOptions = {
      ...FILESTACK_CONFIGURATION.picker_options
    };
    this.filestackClient = filestack.init(FILESTACK_CONFIGURATION.api_key, securityOptions);
  }

  _fileUploadFinishedCallbackHandler(response) {
    this.handleInputTarget.value = response.handle;
    this.filenameInputTarget.value = response.filename;
    Rails.fire(this.filestackUploadFormTarget, 'submit');
  }

  _fileUploadFailedCallbackHandler(event = null) {
    let failureMessage = event?.detail[0]?.error || i18n.t('loan_applications.show.documents_upload.modal_window.file_upload.failure_message');
    this._showToast(failureMessage, 'failure');
  }

  _showToast(message, messageType) {
    this.fileUploadMessageTarget.textContent = message;
    this.fileUploadMessageTarget.classList.add(messageType);
    this.fileUploadMessageTarget.style.visibility = 'visible';
    setTimeout(() => this._hideFileUploadMessage(), 5000);
  }

  _hideFileUploadMessage() {
    this.fileUploadMessageTarget.classList.remove('success', 'failure');
    this.fileUploadMessageTarget.style.visibility = 'hidden';
  }

  _setupFilestackUploadCallbacks() {
    this.filestackUploadFormTarget.addEventListener('ajax:success', (event) => {
      let successMessage = i18n.t('loan_applications.show.documents_upload.modal_window.file_upload.success_message');
      this._showToast(successMessage, 'success');
    });
    this.filestackUploadFormTarget.addEventListener('ajax:error', (event) => this._fileUploadFailedCallbackHandler(event));
  }

  _setupSubmitForReviewCallbacks() {
    this.documentSubmitForReviewFormTarget.addEventListener('ajax:before', (event) => {
      this.submitForReviewButtonTarget.disabled = true;
    });
    this.documentSubmitForReviewFormTarget.addEventListener('ajax:success', (event) => {
      this.element.dataset.status = 'client_submitted';
      this.documentRowController.moveToUnderReview();
      setTimeout(() => this.hideModal(), 2000);
    });
    this.documentSubmitForReviewFormTarget.addEventListener('ajax:error', (event) => {
      this._reviewSubmissionErrorHandler();
    });
  }

  _reviewSubmissionErrorHandler() {
    this.submitForReviewButtonTarget.disabled = false;
    let failureMessage = i18n.t('loan_applications.documents_upload.modal_window.document_submit.failure_message');
    this._showToast(failureMessage, 'failure');
  }

  _toggleFileUploadButtonVisibility(){
    if(this.fileUploadButtonTarget.dataset.showFileUploadButton == "true"){
      this.fileUploadButtonTarget.style.display = 'block';
    } else {
      this.fileUploadButtonTarget.style.display = 'none';
    }
  }

  get documentRowController() {
    let documentRow = document.getElementById(this.data.get('documentRowId'));
    return this.application.getControllerForElementAndIdentifier(documentRow, 'document-row');
  }
}
