import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["uploadableToggle", "checkbox"]

  toggleUploadable(event) {
    this.uploadableToggleTarget.style.pointerEvents = "none";
    let url = this.data.get("toggle-uploadable-path");
    Rails.ajax({
      type: 'PATCH',
      url: url,
      complete: (res) => {
        this.uploadableToggleTarget.style.pointerEvents = "auto";
        var enabled = event.target.checked
        if(res.status != 204){
          event.target.checked = !event.target.checked
          toastr.error("Failed to toggle client file uploads");
        } else {
          toastr.success(i18n.t("activerecord.errors.models.document_dropbox.attributes.uploadable." + ( enabled ? "enabled" : "disabled" )));
        }
      }
    });
  }
}